import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { Order, OrderRepositoryService } from 'reg-hub-common';

@Component({
  selector: 'app-complete-order-list',
  templateUrl: './complete-order-list.component.html',
  styleUrls: ['./complete-order-list.component.css']
})
export class CompleteOrderListComponent {
  @Input() data: Order[] = [];
  @Input() total: number = 0;
  @Input() loading: boolean = false;

  protected displayedColumns = [
    'referenceNumber',
    'orderTypeID',
    'jurisdiction'
  ]

  constructor(
    protected repo: OrderRepositoryService,
    protected router: Router) { }

  protected onViewAll() {
    let params = {
      status: 'Complete',
      sortColumn: 'orderStatusUpdatedDate',
      sortDirection: 'desc'
    }

    this.router.navigate(['/orders'], { queryParams: params })
  }
}

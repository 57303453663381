import { AfterViewInit, Component } from '@angular/core';
import { Router } from '@angular/router';
import { SystemEvent, SystemEventsRepositoryService } from 'reg-hub-common';
import { HttpParams } from '@angular/common/http';
import {
  trigger,
  state,
  style,
  transition,
  animate,
} from '@angular/animations';

@Component({
  selector: 'app-system-event-notification',
  templateUrl: './system-event-notification.component.html',
  styleUrls: ['./system-event-notification.component.css'],
  animations: [
    trigger('expandHeight', [
      transition(':enter', [
        style({ height: '0', opacity: 0 }),
        animate('250ms ease-in-out', style({ height: '*', opacity: 1 })),
      ]),
      transition(':leave', [
        animate('250ms ease-in-out', style({ height: '0', opacity: 0 })),
      ]),
    ]),
  ]
})
export class SystemEventNotificationComponent implements AfterViewInit {
  protected _events: SystemEvent[] = [];
  protected _eventMessage: string | null = null;
  protected _shouldCheckForEvents = true;

  constructor(
    protected systemEventsRepo: SystemEventsRepositoryService) { }

  ngAfterViewInit(): void {
    if (this._shouldCheckForEvents) {
      this.fetchEvents();
    }
  }

  fetchEvents() {
    var defaultParams = new HttpParams().set('isOpen', true);
    defaultParams = defaultParams.append("isCustomerVisible", true);

    this.systemEventsRepo.getFirstPageSubscribable(defaultParams).subscribe(events => {
        this._events = events;
        this._eventMessage = events?.length > 0 ? events[0].message : null;
    });

    setTimeout(() => {
      if (this._shouldCheckForEvents) {
        this.fetchEvents();
      }
    }, 10000);
  }

  closeEventNotification() {
    this._shouldCheckForEvents = false;
    this._events = [];
  }
}
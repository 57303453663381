import { Component } from '@angular/core';
import { catchError, delay, forkJoin, of, takeUntil, takeWhile, tap } from 'rxjs';
import { MatTableDataSource, MatTableDataSourcePaginator } from '@angular/material/table';
import { ClientAuthService } from '../services/auth/client-auth.service';
import { Order, OrderRepositoryService } from 'reg-hub-common';
import { Router } from '@angular/router';
import { InternalSearchCriteria } from 'reg-hub-common';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent {

  protected _loading: boolean = false;
  protected _isSearching: boolean = false;

  protected _draftOrders: Order[] = [];
  protected _totalDraftOrders: number = 0;

  protected _invalidOrders: Order[] = [];
  protected _totalInvalidOrders: number = 0;

  protected _pendingOrders: Order[] = [];
  protected _totalPendingOrders: number = 0;

  protected _completeOrders: Order[] = [];
  protected _totalCompleteOrders: number = 0;

  constructor(
    protected ordersRepo: OrderRepositoryService,
    private router: Router) { }

  ngOnInit(): void {
    this._loading = true;

    const draftOrdersRequest = this.ordersRepo.getTopTenDraftOrders().pipe(catchError(() => of({ total: 0, resource: [] })));
    const invalidOrdersRequest = this.ordersRepo.getTopTenInvalidOrders().pipe(catchError(() => of({ total: 0, resource: [] })));
    const pendingOrdersRequest = this.ordersRepo.getTopTenPendingAndExceptionOrders().pipe(catchError(() => of({ total: 0, resource: [] })));
    const completeOrdersRequest = this.ordersRepo.getTopTenCompleteOrders().pipe(catchError(() => of({ total: 0, resource: [] })));

    //Take the requests for all 4 panels, combine them into one observable, and only set loading to false when all are complete
    forkJoin({
      draftOrders: draftOrdersRequest,
      pendingOrders: pendingOrdersRequest,
      invalidOrders: invalidOrdersRequest,
      completeOrders: completeOrdersRequest
    }).subscribe({ 
      next: responses => {
        this._draftOrders = responses.draftOrders.resource;
        this._totalDraftOrders = responses.draftOrders.total;
        this._invalidOrders = responses.invalidOrders.resource;
        this._totalInvalidOrders = responses.invalidOrders.total;
        this._pendingOrders = responses.pendingOrders.resource;
        this._totalPendingOrders = responses.pendingOrders.total;
        this._completeOrders = responses.completeOrders.resource;
        this._totalCompleteOrders = responses.completeOrders.total;
      },
      complete: () => this._loading = false 
    });
  }

  onSearch(search: InternalSearchCriteria) {
    this._isSearching = true;

    switch (search.typeOfSearch) {
        case 'referenceNumber':
            this.searchByReferenceNumber(search.criteria);
            break;
        case 'firstName':
            this.searchByFirstName(search.criteria);
            break;
        case 'lastName':
            this.searchByLastName(search.criteria);
            break;
        case 'businessName':
            this.searchByBusinessName(search.criteria);
            break;
        case 'serialNumber':
            this.searchBySerialNumber(search.criteria);
            break;
        case 'dateOfBirth':
            this.searchByDateOfBirth(search.criteria);
            break;
        case 'orderId':
            this.searchByOrderId(search.criteria);
            break;
        case 'registrationNumber':
            this.searchByRegistrationNumber(search.criteria);
            break;
        default:
            console.error('Invalid search option');
            break;
    }
  }

  searchByReferenceNumber(referenceNumber: string) {
    let params = {
      autoExpand: false,
      autoRedirect: true,
      referenceNumber: referenceNumber,
    }

    this.router.navigate(['/orders'], { queryParams: params })
  }

  searchByFirstName(firstName: string) {
    let params = {
      autoExpand: false,
      autoRedirect: true,
      firstName: firstName,
    }

    this.router.navigate(['/orders'], { queryParams: params })
  }

  searchByLastName(lastName: string) {
    let params = {
      autoExpand: false,
      autoRedirect: true,
      lastName: lastName,
    }

    this.router.navigate(['/orders'], { queryParams: params })
  }

  searchByBusinessName(businessName: string) {
    let params = {
      autoExpand: false,
      autoRedirect: true,
      businessName: businessName,
    }

    this.router.navigate(['/orders'], { queryParams: params })
  }

  searchBySerialNumber(serialNumber: string) {
    let params = {
      autoExpand: false,
      autoRedirect: true,
      serialNumber: serialNumber,
    }

    this.router.navigate(['/orders'], { queryParams: params })
  }

  searchByDateOfBirth(dateOfBirth: string) {
    let params = {
      autoExpand: false,
      autoRedirect: true,
      dateOfBirth: dateOfBirth,
    }

    this.router.navigate(['/orders'], { queryParams: params })
  }

  searchByRegistrationNumber(registrationNumber: string) {
    const lienOrderTypes = [
      'Lien',
      'Renewal',
      'Amendment',
      'Discharge',
      'BasicRenewal',
      'BasicDischarge'
  ];

    let params = {
      autoExpand: false,
      autoRedirect: true,
      orderTypes: lienOrderTypes,
      registrationNumber: registrationNumber
    }

    this.router.navigate(['/orders'], { queryParams: params })
  }

  searchByOrderId(orderId: string) {
      this.router.navigate(['orders', orderId]);
  }
}

<mat-card>
    <!-- <mat-card-header>
        <mat-card-title>Actions</mat-card-title>
    </mat-card-header> -->
    <mat-card-content>
        <div class="button-container">
            <button mat-flat-button *ngIf="order.orderStatusTypeID == 'Historical'" color="primary" (click)="edit()">Edit</button>
            <button *ngIf="uiConfiguration.hasAmendments" mat-flat-button color="primary" (click)="amend()">Amend</button>
            <button *ngIf="!order.lien!.isInfiniteTerm && uiConfiguration.hasRenewals" mat-flat-button color="primary" (click)="renew()">Renew</button>
            <button *ngIf="uiConfiguration.hasDischarges" mat-flat-button color="warn" (click)="discharge()">Discharge</button>
        </div>
    </mat-card-content>
</mat-card>
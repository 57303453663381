<mat-expansion-panel [expanded]="autoExpand">
    <mat-expansion-panel-header>
        <mat-panel-title class="bold">
            Filters
        </mat-panel-title>
    </mat-expansion-panel-header>
    <form [formGroup]="_filterForm" class="padding">
        <div class="flex-container">
            <mat-form-field class="form-field flex-item">
                <mat-label>Date Opened From</mat-label>
                <input matInput [matDatepicker]="dateOpenedFrom" [formControlName]="'dateOpenedFrom'"
                    placeholder="Date Opened From">
                <mat-datepicker-toggle matSuffix [for]="dateOpenedFrom"></mat-datepicker-toggle>
                <mat-datepicker #dateOpenedFrom></mat-datepicker>
            </mat-form-field>
            <mat-form-field class="form-field flex-item">
                <mat-label>Date Opened To</mat-label>
                <input matInput [matDatepicker]="dateOpenedTo" [formControlName]="'dateOpenedTo'"
                    placeholder="Date Opened To">
                <mat-datepicker-toggle matSuffix [for]="dateOpenedTo"></mat-datepicker-toggle>
                <mat-datepicker #dateOpenedTo></mat-datepicker>
            </mat-form-field>
        </div>
        <div class="flex-container">
            <mat-checkbox formControlName="isClosed" class="form-field flex-item">
                Closed
            </mat-checkbox>
            <mat-form-field class="form-field flex-item">
                <mat-label>Date Closed From</mat-label>
                <input matInput [matDatepicker]="dateClosedFrom" [formControlName]="'dateClosedFrom'"
                    placeholder="Date Closed From">
                <mat-datepicker-toggle matSuffix [for]="dateClosedFrom"></mat-datepicker-toggle>
                <mat-datepicker #dateClosedFrom></mat-datepicker>
            </mat-form-field>
            <mat-form-field class="form-field flex-item">
                <mat-label>Date Closed To</mat-label>
                <input matInput [matDatepicker]="dateClosedTo" [formControlName]="'dateClosedTo'"
                    placeholder="Date Closed To">
                <mat-datepicker-toggle matSuffix [for]="dateClosedTo"></mat-datepicker-toggle>
                <mat-datepicker #dateClosedTo></mat-datepicker>
            </mat-form-field>
        </div>
        <div class="flex-container">
            <mat-checkbox formControlName="isExported" class="form-field flex-item">
                Exported
            </mat-checkbox>
            <mat-form-field class="form-field flex-item">
                <mat-label>Date Exported From</mat-label>
                <input matInput [matDatepicker]="dateExportedFrom" [formControlName]="'dateExportedFrom'"
                    placeholder="Date Exported From">
                <mat-datepicker-toggle matSuffix [for]="dateExportedFrom"></mat-datepicker-toggle>
                <mat-datepicker #dateExportedFrom></mat-datepicker>
            </mat-form-field>
            <mat-form-field class="form-field flex-item">
                <mat-label>Date Exported To</mat-label>
                <input matInput [matDatepicker]="dateExportedTo" [formControlName]="'dateExportedTo'"
                    placeholder="Date Exported To">
                <mat-datepicker-toggle matSuffix [for]="dateExportedTo"></mat-datepicker-toggle>
                <mat-datepicker #dateExportedTo></mat-datepicker>
            </mat-form-field>
        </div>
        <div class="flex-container">
            <mat-checkbox formControlName="isPaid" class="form-field flex-item">
                Paid
            </mat-checkbox>
            <mat-form-field class="form-field flex-item">
                <mat-label>Date Paid From</mat-label>
                <input matInput [matDatepicker]="datePaidFrom" [formControlName]="'datePaidFrom'"
                    placeholder="Date Paid From">
                <mat-datepicker-toggle matSuffix [for]="datePaidFrom"></mat-datepicker-toggle>
                <mat-datepicker #datePaidFrom></mat-datepicker>
            </mat-form-field>
            <mat-form-field class="form-field flex-item">
                <mat-label>Date Paid To</mat-label>
                <input matInput [matDatepicker]="datePaidTo" [formControlName]="'datePaidTo'"
                    placeholder="Date Paid To">
                <mat-datepicker-toggle matSuffix [for]="datePaidTo"></mat-datepicker-toggle>
                <mat-datepicker #datePaidTo></mat-datepicker>
            </mat-form-field>
        </div>
        <div>
            <button mat-raised-button color="accent" class="padding-sides-5" aria-label="Clear filters button"
                (click)="onClear()" [disabled]="!_filterForm.dirty">
                Clear Filters
            </button>
            <button mat-raised-button color="accent" class="padding-sides-5" aria-label="Filter button" type="submit"
                (click)="onFilter()">
                Filter
            </button>
        </div>
    </form>
</mat-expansion-panel>
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Order } from 'projects/reg-hub-common/src/lib/interfaces/orders/order';
import { GeneralCollateralUIConfiguration } from 'projects/reg-hub-common/src/public-api';
import { GeneralCollateralHistory, LienRepositoryService, OrderGroupsService, ValidationProblem } from 'reg-hub-common';
import { BehaviorSubject, Observable, Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-general-collateral',
  templateUrl: './general-collateral.component.html',
  styleUrls: ['./general-collateral.component.css']
})
export class GeneralCollateralComponent implements OnInit, OnDestroy {
  @Input() isDisabled: boolean = false;
  @Input() order!: Order;
  @Input() errors$!: Observable<ValidationProblem[] | undefined>;
  @Input() uiConfiguration!: GeneralCollateralUIConfiguration;

  public generalCollateralDetails!: FormGroup;

  collateralValueError$: BehaviorSubject<string | null | undefined> = new BehaviorSubject<string | null | undefined>(null);
  collateralToAddError$: BehaviorSubject<string | null | undefined> = new BehaviorSubject<string | null | undefined>(null);
  collateralToRemoveError$: BehaviorSubject<string | null | undefined> = new BehaviorSubject<string | null | undefined>(null);

  private onDestroy$ = new Subject<void>();

  @Output() generalCollateralFormValueChangedEvent = new EventEmitter<any>();

  protected textareaRows: number = 5;
  
  constructor(
    private formBuilder: FormBuilder,
    private lienRepo: LienRepositoryService) {}

  ngOnInit(): void {
    this.ensureOrder();

    this.textareaRows = this.uiConfiguration.showCollateralToAdd || this.uiConfiguration.showCollateralToRemove ? 5 : 8;

    this.generalCollateralDetails = this.formBuilder.group({ })

    if(this.uiConfiguration.showCollateralValue) {
      this.generalCollateralDetails.addControl('collateralValue', this.formBuilder.control(this.order.lien?.generalCollateral.collateralValue));
    }

    if(this.uiConfiguration.showCollateralToAdd) {
      this.generalCollateralDetails.addControl('collateralToAdd', this.formBuilder.control(this.order.lien?.generalCollateral?.collateralToAdd));
    }

    if(this.uiConfiguration.showCollateralToRemove) {
      this.generalCollateralDetails.addControl('collateralToRemove', this.formBuilder.control(this.order.lien?.generalCollateral?.collateralToRemove));
    }

    if(this.uiConfiguration.showSecurityInterest) {
      this.generalCollateralDetails.addControl('securityInterest', this.formBuilder.control(this.order.lien?.securityInterest));
    }

    if (this.isDisabled) {
      this.generalCollateralDetails.disable();
    } else {
      this.setTextBoxDisability();
    }

    this.generalCollateralDetails.valueChanges
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(() => {
        this.setTextBoxDisability();
        this.generalCollateralFormValueChangedEvent.emit(this.generalCollateralDetails.value);
      });

    this.errors$
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(errors => this.pushErrors(errors));
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  public saveContents() {
    this.lienRepo.updateLien("orders/" + this.order.id + "/Lien", this.order.lien!)
    .subscribe(result => {
      const orderId = (result as any).resource.id;
    })
  }

  public setTextBoxDisability() {
    var collateralValueControl = this.generalCollateralDetails.get('collateralValue');
    var addCollateralControl = this.generalCollateralDetails.get('collateralToAdd');
    var removecollateralControl = this.generalCollateralDetails.get('collateralToRemove');

    if(collateralValueControl?.value) {
      addCollateralControl?.disable({ emitEvent: false });
      removecollateralControl?.disable({ emitEvent: false });

      if(!collateralValueControl.enabled) {
        collateralValueControl.enable({ emitEvent: false });
      }
    } else if(addCollateralControl?.value || removecollateralControl?.value) {
      collateralValueControl?.disable({ emitEvent: false });

      if(!addCollateralControl?.enabled) {
        addCollateralControl?.enable({ emitEvent: false });
      }

      if(!removecollateralControl?.enabled) {
        removecollateralControl?.enable({ emitEvent: false });
      }
    } else {
      collateralValueControl?.enable({ emitEvent: false });
      addCollateralControl?.enable({ emitEvent: false });
      removecollateralControl?.enable({ emitEvent: false });
    }
  }

  private pushErrors(errors: ValidationProblem[] | undefined) {
    if(!errors) {
      return;
    }

    this.collateralValueError$.next(errors.filter(error => 
      (error.path.includes('/collateral') || 
      error.path.includes('/generalcollateral')) &&
      !error.path.includes('liendetails') &&
      !error.path.includes('/collateraltoremove') &&
      !error.path.includes('/collateraltoadd'))?.at(0)?.userFriendlyMessage);

    this.collateralToAddError$.next(errors.filter(error => 
      (error.path.includes('/collateral') || 
      error.path.includes('/generalcollateral')) &&
      !error.path.includes('liendetails') &&
      !error.path.includes('/collateraltoremove') &&
      !error.path.includes('/collateralvalue'))?.at(0)?.userFriendlyMessage);

    this.collateralToRemoveError$.next(errors.filter(error => 
      (error.path.includes('/collateral') || 
      error.path.includes('/generalcollateral')) &&
      !error.path.includes('liendetails') &&
      !error.path.includes('/collateralvalue') &&
      !error.path.includes('/collateraltoadd'))?.at(0)?.userFriendlyMessage);
  }

  ensureOrder(): void {
    if(!this.order.lien) {
      this.order.lien = {
        id: "",
        added: new Date(),
        orderID: "",
        registrationNumber: "",
        registrationDate: new Date(),
        expiryDate: new Date(),
        term: 0,
        amount: 0,
        generalCollateral: {
          collateralValue: ""
        },
        additionalInformation: "",
        document: {
          id: "",
          added: new Date(),
          documentTypeID: "",
          fileName: "",
          storagePath: "",
          storageID: ""
        },
        securityInterest: false,
        trustIndenture: false,
        isInfiniteTerm: false,
        authorizationReceived: true
      }
    }

    if(!this.order.lien.generalCollateral) {
      this.order.lien.generalCollateral = {
        collateralValue: ''
      }
    }
  }
}

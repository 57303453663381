<mat-expansion-panel [expanded]="autoExpand">
    <mat-expansion-panel-header>
        <mat-panel-title class="bold">
            Filters
        </mat-panel-title>
    </mat-expansion-panel-header>
    <form [formGroup]="_filterForm" class="padding">
        <div class="flex-container">
            <mat-form-field class="form-field flex-item">
                <mat-label>Reference Number</mat-label>
                <input matInput [formControlName]="'referenceNumber'" placeholder="Reference Number" />
            </mat-form-field>
            <mat-form-field class="form-field flex-item">
                <mat-select [formControlName]="'orderTypes'" placeholder="Order Type" multiple="true">
                    <mat-option *ngFor="let option of _orderTypeOptions" [value]="option.value">
                        {{ option.label }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field class="form-field flex-item">
                <mat-select [formControlName]="'status'" placeholder="Order Status" multiple="true">
                    <mat-option *ngFor="let option of _orderStatusTypeOptions" [value]="option.value">
                        {{ option.label }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>

        <lib-jurisdiction-selection (formReady)="jurisdictionFormChanges($event)" [defaultCountry]="'CA'"></lib-jurisdiction-selection>

        <div class="flex-container">
            <mat-form-field class="form-field flex-item">
                <mat-select [formControlName]="'userGroupID'" placeholder="User Group" (selectionChange)="onUserGroupChanged($event)">
                    <mat-option *ngFor="let option of _userGroups" [value]="option.hasAccessToUserGroupID">
                        {{ option.hasAccessToUserGroup?.name }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field class="form-field flex-item" *ngIf="_users.length > 0">
                <mat-select [formControlName]="'userID'" placeholder="User">
                    <mat-option *ngFor="let option of _users" [value]="option.value">
                        {{ option.label }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>


        <div class="flex-container">
            <mat-form-field class="form-field flex-item">
                <mat-label>Date Requested From</mat-label>
                <input matInput [matDatepicker]="dateRequestedFrom" [formControlName]="'dateRequestedFrom'"
                    placeholder="Date Requested From">
                <mat-datepicker-toggle matSuffix [for]="dateRequestedFrom"></mat-datepicker-toggle>
                <mat-datepicker #dateRequestedFrom></mat-datepicker>
            </mat-form-field>
            <mat-form-field class="form-field flex-item">
                <mat-label>Date Requested To</mat-label>
                <input matInput [matDatepicker]="dateRequestedTo" [formControlName]="'dateRequestedTo'"
                    placeholder="Date Requested To">
                <mat-datepicker-toggle matSuffix [for]="dateRequestedTo"></mat-datepicker-toggle>
                <mat-datepicker #dateRequestedTo></mat-datepicker>
            </mat-form-field>
        </div>

        <div class="flex-container">
            <mat-form-field class="form-field flex-item">
                <mat-label>Date Last Updated From</mat-label>
                <input matInput [matDatepicker]="orderStatusUpdatedDateFrom"
                    [formControlName]="'orderStatusUpdatedDateFrom'" placeholder="Date Last Updated From">
                <mat-datepicker-toggle matSuffix [for]="orderStatusUpdatedDateFrom"></mat-datepicker-toggle>
                <mat-datepicker #orderStatusUpdatedDateFrom></mat-datepicker>
            </mat-form-field>
            <mat-form-field class="form-field flex-item">
                <mat-label>Date Last Updated To</mat-label>
                <input matInput [matDatepicker]="orderStatusUpdatedDateTo" [formControlName]="'orderStatusUpdatedDateTo'"
                    placeholder="Date Last Updated To">
                <mat-datepicker-toggle matSuffix [for]="orderStatusUpdatedDateTo"></mat-datepicker-toggle>
                <mat-datepicker #orderStatusUpdatedDateTo></mat-datepicker>
            </mat-form-field>
        </div>

        <div class="flex-container" *ngIf="lienSelected()">
            <mat-form-field class="form-field flex-item">
                <mat-label>Registration #</mat-label>
                <input matInput [formControlName]="'registrationNumber'" placeholder="Registration #" />
            </mat-form-field>
            <mat-form-field class="form-field flex-item">
                <mat-label>Registration Date From</mat-label>
                <input matInput [matDatepicker]="registrationDateFrom"
                    [formControlName]="'registrationDateFrom'" placeholder="Registration Date From">
                <mat-datepicker-toggle matSuffix [for]="registrationDateFrom"></mat-datepicker-toggle>
                <mat-datepicker #registrationDateFrom></mat-datepicker>
            </mat-form-field>
            <mat-form-field class="form-field flex-item">
                <mat-label>Registration Date To</mat-label>
                <input matInput [matDatepicker]="registrationDateTo" [formControlName]="'registrationDateTo'"
                    placeholder="Registration Date To">
                <mat-datepicker-toggle matSuffix [for]="registrationDateTo"></mat-datepicker-toggle>
                <mat-datepicker #registrationDateTo></mat-datepicker>
            </mat-form-field>
        </div>

        <div class="spacer-small"></div>
        <mat-divider></mat-divider>
        <div class="spacer-small"></div>

        <div class="flex-container">
            <mat-form-field class="form-field flex-item">
                <mat-label>First Name</mat-label>
                <input matInput [formControlName]="'firstName'" placeholder="First Name" />
            </mat-form-field>
            <mat-form-field class="form-field flex-item">
                <mat-label>Last Name</mat-label>
                <input matInput [formControlName]="'lastName'" placeholder="Last Name" />
            </mat-form-field>
            <mat-form-field class="form-field flex-item">
                <mat-label>Date of Birth</mat-label>
                <input matInput [matDatepicker]="dateOfBirth" [formControlName]="'dateOfBirth'"
                    placeholder="Date of Birth">
                <mat-datepicker-toggle matSuffix [for]="dateOfBirth"></mat-datepicker-toggle>
                <mat-datepicker #dateOfBirth></mat-datepicker>
            </mat-form-field>
        </div>

        <div class="flex-container">
            <mat-form-field class="form-field flex-item">
                <mat-label>Business Name</mat-label>
                <input matInput [formControlName]="'businessName'" placeholder="Business Name" />
            </mat-form-field>
            <mat-form-field class="form-field flex-item">
                <mat-label>Serial Number</mat-label>
                <input matInput [formControlName]="'serialNumber'" placeholder="Serial Number" />
            </mat-form-field>
        </div>
        <div class="spacer-small"></div>
        <div>
            <button mat-raised-button color="accent" class="padding-sides-5" aria-label="Clear filters button"
                (click)="onClear()" [disabled]="!_filterForm.dirty">
                Clear Filters
            </button>
            <button mat-raised-button color="accent" class="padding-sides-5" aria-label="Filter button" type="submit"
                (click)="onFilter()">
                Filter
            </button>
        </div>
    </form>
</mat-expansion-panel>
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
import { Idle } from '@ng-idle/core';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppComponent } from './app.component';
import { LoggedOutComponent } from './auth/logged-out/logged-out.component';
import { UiCommonModule, REG_HUB_DATE_FORMATS } from 'reg-hub-common';
import { AppRoutingModule } from './app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatListModule } from '@angular/material/list';
import { RouterModule } from '@angular/router';
import { TopMenubarComponent } from './navigation/top-menubar/top-menubar.component';
import { SideMenuComponent } from './navigation/side-menu/side-menu.component';
import { ToastContainerDirective, ToastrModule } from 'ngx-toastr';
import { MatChipsModule } from '@angular/material/chips';
import { MatTableModule } from '@angular/material/table';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatSelectModule } from '@angular/material/select';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MAT_DATE_FORMATS, MatNativeDateModule, MatRippleModule } from '@angular/material/core';
import { MatRadioModule } from '@angular/material/radio';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatDialogModule } from '@angular/material/dialog';
import { MatBadgeModule } from '@angular/material/badge';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSortModule } from '@angular/material/sort';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatTabsModule } from '@angular/material/tabs';
import { DashboardComponent } from './dashboard/dashboard.component';
import { OrdersModule } from './orders/orders.module';
import { environment } from '../environments/environment';
import { SearchOrdersFilterComponent } from './orders/search-orders-filter/search-orders-filter.component';
import { SearchOrdersComponent } from './orders/search-orders/search-orders.component';
import { ManagementModule } from './management/management.module';
import { NotificationComponent } from './navigation/notification/notification.component';
import { DatePipe } from '@angular/common';
import { AccountingModule } from './accounting/accounting.module';
import { CallbackComponent } from './auth/callback/callback.component';
import { httpInterceptorProviders } from './services/http-interceptors';
import { ReportsModule } from './reporting/reporting.module';
import { SystemEventNotificationComponent } from './system-event-notification/system-event-notification.component';
import { MyAccountComponent } from './auth/my-account/my-account.component';
import { InformationDocumentsComponent } from './information-documents/information-documents.component';
import { ContactUsComponent } from './contact-us/contact-us.component';

@NgModule({
  declarations: [
    AppComponent,
    LoggedOutComponent,
    TopMenubarComponent,
    SideMenuComponent,
    DashboardComponent,
    SearchOrdersFilterComponent,
    SearchOrdersComponent,
    NotificationComponent,
    CallbackComponent,
    SystemEventNotificationComponent,
    MyAccountComponent,
    InformationDocumentsComponent,
    ContactUsComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    OrdersModule,
    ManagementModule,
    AccountingModule,
    ReportsModule,

    // Material Design imports
    MatProgressSpinnerModule,
    MatMenuModule,
    MatIconModule,
    MatSidenavModule,
    MatListModule,
    MatChipsModule,
    MatTableModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatDividerModule,
    MatToolbarModule,
    MatCardModule,
    MatCheckboxModule,
    MatTooltipModule,
    MatSelectModule,
    MatProgressBarModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatRadioModule,
    MatExpansionModule,
    MatSnackBarModule,
    MatDialogModule,
    MatBadgeModule,
    MatPaginatorModule,
    MatSlideToggleModule,
    MatSortModule,
    MatGridListModule,
    MatTabsModule,
    MatRippleModule,
    FormsModule,
    ReactiveFormsModule,
    
    RouterModule,
    ToastrModule.forRoot({
      extendedTimeOut: 0,
      timeOut: 0,
      maxOpened: 4,
      positionClass: 'inline',
      easeTime: 0,
      preventDuplicates: true
    }),
    ToastContainerDirective,
    UiCommonModule.forRoot({ rootUrl: environment.urlAddress, batchRootURL: environment.batchUrlAddress }),
    NgIdleKeepaliveModule.forRoot()
  ],
  providers: [
    httpInterceptorProviders,
    DatePipe,
    Idle,
    { provide: MAT_DATE_FORMATS, useValue: REG_HUB_DATE_FORMATS }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

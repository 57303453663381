import { Component, OnInit, OnDestroy } from '@angular/core';
import { Lien, Order, OrderManagerService, OrderMessagesComponent, OrderRepositoryService, OrderStateRepositoryService, eOrderType, eOrderTypeGroup } from 'reg-hub-common';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmSubmitComponent } from '../confirm-submit/confirm-submit.component';
import { takeUntil } from 'rxjs/operators';
import { Subject, timer } from 'rxjs';
import { ResultsPollerService } from '../../services/results-poller/results-poller.service';
import { ConfirmDeleteComponent } from '../confirm-delete/confirm-delete.component';
import { ConfirmCancelComponent } from '../confirm-cancel/confirm-cancel.component';
import { ConfirmHistoricalComponent } from '../confirm-historical/confirm-historical.component';
import { NavigationHistoryService } from '../../services/navigation-history-service/navigation-history.service';

@Component({
  selector: 'app-new-registration',
  templateUrl: './new-registration.component.html',
  styleUrls: ['./new-registration.component.css']
})
export class NewRegistrationComponent implements OnInit, OnDestroy {
  private unsubscribe$ = new Subject<void>();

  orderId: string = "";
  order: Order = {
    referenceNumber: "",
    country: "CA",
    jurisdiction: "",
    orderTypeID: eOrderType.notSet,
    lien: {} as Lien
  } as Order;

  parentOrder: Order = {
    referenceNumber: "",
    country: "CA",
    jurisdiction: "",
    orderTypeID: eOrderType.notSet,
    lien: {} as Lien
  } as Order;

  orderTypeGroupID: eOrderTypeGroup = eOrderTypeGroup.PPSARegistration;
  isLoadingOrder: boolean = false;
  finalStepIsSelected: boolean = false;

  constructor(private activatedRoute: ActivatedRoute,
    private orderManager: OrderManagerService,
    private orderRepo: OrderRepositoryService,
    private orderStateRepo: OrderStateRepositoryService,
    private dialog: MatDialog,
    private router: Router,
    private resultPoller: ResultsPollerService,
    private navigationService: NavigationHistoryService
  ) { }

  ngOnInit(): void {
    this.orderManager.currentOrder
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(order => this.order = order);

    this.orderManager.parentOrder
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(parentOrder => this.parentOrder = parentOrder);

    this.activatedRoute.paramMap.subscribe(params => {
      this.orderId = params.get('orderID')!;
      if (this.orderId) {
        // If we have an orderID, load the associated order and stepper
        this.getOrder(this.orderId);
      } else {
        // if we don't have an orderID, load base stepper
        this.resetOrder();
        this.orderManager.updateOrder(this.order);
      }
    });
  }

  ngOnDestroy(): void {
    // Signal all subscriptions to complete
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private getOrder = (orderID: string) => {
    // this wil call api/v1/orders/id
    this.isLoadingOrder = true;
    this.orderRepo.getOrder('orders', orderID)
      .subscribe(order => {
        this.isLoadingOrder = false;
        this.order = order as Order;
        this.orderManager.updateOrder(this.order);
      })
  }

  messageCount() {
    let length = this.order?.orderMessages?.filter((u) => u.isCustomerVisible && !u.isReadByCustomer).length ?? 0;
    return length > 0 ? length.toString() : "";
  }

  openOrderMessages() {
    this.dialog.open(OrderMessagesComponent, {
      data: { order: this.order, customer: true },
      minWidth: '800px'
    });
  }

  submissionConfirmation() {
    const dialogRef = (this.order?.orderStatusTypeID == "HistoricalDraft") ?
      this.dialog.open(ConfirmHistoricalComponent) :
      this.dialog.open(ConfirmSubmitComponent);

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        // submission is confirmed, submit order
        this.submitOrder();
      }
    });
  }

  submitOrder() {
    //Limit flashing of order spinner
    var timerSubscription = timer(250).subscribe(() => this.isLoadingOrder = true);

    const pendingStatus = {
      newStatus: (this.order?.orderStatusTypeID == "HistoricalDraft") ? "Historical" : "Pending",
      status: "",
      message: "Submitted from Reg Hub Client"
    }

    this.orderStateRepo.updateOrderState(this.order.id, pendingStatus)
      .subscribe({
        next: () => {
          timerSubscription.unsubscribe();
          this.isLoadingOrder = false;
          this.resultPoller.startPolling(this.order.id);
          this.order.orderStatusTypeID = "Pending";
          this.orderManager.updateOrder(this.order);
          this.router.navigate(['orders', this.order.id]);
        },
        error: () => {
          timerSubscription.unsubscribe();
          this.isLoadingOrder = false;
        }
      });
  }

  deletionConfirmation() {
    if (this.order?.orderStatusTypeID == "Draft" ||
      this.order?.orderStatusTypeID == "HistoricalDraft") {
      const dialogRef = this.dialog.open(ConfirmDeleteComponent);

      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          // deletion is confirmed, delet order
          this.deleteOrder();
        }
      });
    }
  }

  deleteOrder() {
    const orderID = this.order.id;

    this.orderRepo.deleteOrder("Orders/" + orderID)
      .subscribe({
        next: () => {
          let previousUrl = this.navigationService.getPreviousUrl();

          if(previousUrl?.toString()?.includes(orderID)) {
            previousUrl = this.navigationService.getPreviousUrl(1);
          }
          
          this.router.navigateByUrl(previousUrl);
        },
        error: (error) => {
          console.log(error);
        }
      });
  }

  cancelConfirmation() {
    const dialogRef = this.dialog.open(ConfirmCancelComponent);

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        // deletion is confirmed, delet order
        this.cancelOrder();
      }
    });
  }

  cancelOrder() {
    var timerSubscription = timer(250).subscribe(() => this.isLoadingOrder = true);

    const pendingStatus = {
      newStatus: "Cancelled",
      status: "",
      message: "Cancelled from Reg Hub Client"
    }

    this.orderStateRepo.updateOrderState(this.order.id, pendingStatus)
      .subscribe({
        next: (result) => {
          timerSubscription.unsubscribe();
          this.isLoadingOrder = false;

          if (!result) {
            this.resultPoller.startPolling(this.order.id);
            this.order.orderStatusTypeID = "Cancelled";
            this.orderManager.updateOrder(this.order);
            this.router.navigate(['orders', this.order.id]);
          }
        },
        error: () => {
          timerSubscription.unsubscribe();
          this.isLoadingOrder = false;
        }
      });
  }

  private resetOrder() {
    this.order = {
      referenceNumber: "",
      country: "CA",
      jurisdiction: "",
      orderTypeID: eOrderType.notSet,
      lien: {} as Lien
    } as Order;
  }
}

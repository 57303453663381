import { Injectable } from '@angular/core';
import { NotificationService, OrderStateRepositoryService, eOrderStatusType } from 'reg-hub-common';
import { interval, Subject, takeUntil, switchMap } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ResultsPollerService {

  private stopPollingMap = new Map<string, Subject<void>>();

  constructor(private orderState: OrderStateRepositoryService,
    private notificationService: NotificationService) {}

  startPolling(orderID: string) {
    // If there's already a polling subject for this orderID, do nothing
    if (this.stopPollingMap.has(orderID)) {
      return;
    }

    const stopPolling = new Subject<void>();
    this.stopPollingMap.set(orderID, stopPolling);

    interval(5000)
      .pipe(
        takeUntil(stopPolling),
        switchMap(() => this.orderState.getOrderState(orderID))
      )
      .subscribe({
        next: (response) => {
          if (response.status?.toLowerCase() == "complete") {
            // stop polling and trigger alert.
            this.stopPollingService(orderID);

            // publish Notification
            this.notificationService.addNotification("Order Completed", `A recently requested order has completed.`, orderID)
          }
        },
        error: (error) => {
          // Handle errors here
          console.error(error);
        },
      });

    // Automatically stop polling after 60 seconds
    setTimeout(() => this.stopPollingService(orderID), 60000);
  }

  stopPollingService(orderID: string) {
    const stopPolling = this.stopPollingMap.get(orderID);
    if (stopPolling) {
      stopPolling.next();
      stopPolling.complete();
      this.stopPollingMap.delete(orderID);
    }
  }
}

<div class="padding">
    <mat-card>
        <mat-card-content>
            <app-invoices-filter></app-invoices-filter>
            <div class="table-container">
                <table mat-table matSort [dataSource]="this.repo.resource$" multiTemplateDataRows
                    class="mat-elevation-z8" (matSortChange)="sort($event)"
                    [class.table-loading]="this.repo.loading$ | async">

                    <!-- Invoice ID -->
                    <ng-container matColumnDef="id">
                        <th mat-header-cell *matHeaderCellDef> ID </th>
                        <td mat-cell *matCellDef="let invoice"> {{invoice.id}} </td>
                    </ng-container>

                    <!-- Billing Cycle -->
                    <ng-container matColumnDef="billingCycle">
                        <th mat-header-cell *matHeaderCellDef> Billing Cycle </th>
                        <td mat-cell *matCellDef="let invoice"> {{invoice.billingCycle.name}} </td>
                    </ng-container>

                    <!-- Date Opened -->
                    <ng-container matColumnDef="dateOpened">
                        <th mat-header-cell *matHeaderCellDef> Date Opened </th>
                        <td mat-cell *matCellDef="let invoice"> {{invoice.dateOpened | localizedDate:'yyyy-MM-dd'}} </td>
                    </ng-container>

                    <!-- Date Closed -->
                    <ng-container matColumnDef="dateClosed">
                        <th mat-header-cell *matHeaderCellDef> Date Closed </th>
                        <td mat-cell *matCellDef="let invoice"> {{invoice.dateClosed | localizedDate:'yyyy-MM-dd'}} </td>
                    </ng-container>

                    <!-- Date Exported -->
                    <ng-container matColumnDef="dateExported">
                        <th mat-header-cell *matHeaderCellDef> Date Exported </th>
                        <td mat-cell *matCellDef="let invoice"> {{invoice.dateExported | localizedDate:'yyyy-MM-dd'}} </td>
                    </ng-container>

                    <!-- Date Paid -->
                    <ng-container matColumnDef="datePaid">
                        <th mat-header-cell *matHeaderCellDef> Date Paid </th>
                        <td mat-cell *matCellDef="let invoice"> {{invoice.datePaid | localizedDate:'yyyy-MM-dd'}} </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;" [routerLink]="['/accounting/invoices/', row.id]">
                    </tr>
                </table>
            </div>
        </mat-card-content>
        <mat-paginator *ngIf="(this.repo.resourceCount$ | async) ?? 0 > 0" [pageSize]="this.repo.pageSize$ | async"
            [length]="this.repo.resourceCount$ | async" [pageIndex]="this.repo.pageNum$ | async"
            [pageSizeOptions]="[10, 25, 50]" (page)="page($event)"></mat-paginator>
    </mat-card>
</div>
import { Component, Input } from '@angular/core';
import { Order } from 'reg-hub-common';

@Component({
  selector: 'app-discharge-details',
  templateUrl: './discharge-details.component.html',
  styleUrls: ['./discharge-details.component.css']
})
export class DischargeDetailsComponent {
  @Input() order!: Order;

  constructor() { }
}
<div class="wrapper" *ngIf="isAuthenticated">
  <app-top-menubar [(isSidenavOpen)]="this.isSidenavOpen"></app-top-menubar>
  <app-system-event-notification></app-system-event-notification>
  <app-side-menu [isSidenavOpen]="this.isSidenavOpen" class="flex-item-no-padding"></app-side-menu>

  <div class="notification-container">
    <app-notification *ngFor="let notification of notifications" [notification]="notification"></app-notification>
  </div>
</div>

<div class="redirect" *ngIf="!isAuthenticated">
  <div class="block">
    <div class="flex-container-center">
        <mat-spinner></mat-spinner>
    </div>
    <div class="spacer-large">
    </div>
    <span>Redirecting to login...</span>
  </div>
</div>
<mat-sidenav-container>
    <mat-sidenav [opened]="isSidenavOpen" mode="side">
        <div class="mat-sidenav-wrapper">
            <div class="mat-nav-wrapper">
                <mat-nav-list>
                    <a mat-list-item *ngFor="let item of navItems" [routerLink]="item.link" class="inactiveRow" routerLinkActive="activeRow">
                        <div class="list-item">
                            <span class="list-item-icon" routerLinkActive="activeIcon"><mat-icon>{{ item.icon }}</mat-icon></span>
                            <span class="list-item-label" routerLinkActive="activeText">{{ item.label }}</span>
                        </div>
                    </a>
                </mat-nav-list>
                <mat-divider></mat-divider>
                <div mat-subheader class="section-subheader">NEW REQUEST</div>
                <mat-nav-list>
                    <a mat-list-item *ngFor="let item of requestItems" [routerLink]="item.link" class="inactiveRow" routerLinkActive="activeRow">
                        <div class="list-item">
                            <span class="list-item-icon" routerLinkActive="activeIcon"><mat-icon>{{ item.icon }}</mat-icon></span>
                            <span class="list-item-label" routerLinkActive="activeText">{{ item.label }}</span>
                        </div>
                    </a>
                </mat-nav-list>
                <mat-divider></mat-divider>
                <div mat-subheader class="section-subheader">HELP</div>
                <mat-nav-list>
                    <a mat-list-item *ngFor="let item of helpItems" [routerLink]="item.link" class="inactiveRow" routerLinkActive="activeRow">
                        <div class="list-item">
                            <span class="list-item-icon" routerLinkActive="activeIcon"><mat-icon>{{ item.icon }}</mat-icon></span>
                            <span class="list-item-label" routerLinkActive="activeText">{{ item.label }}</span>
                        </div>
                    </a>
                </mat-nav-list>
            </div>
        </div>
    </mat-sidenav>

    <mat-sidenav-content>
        <div toastContainer aria-live="polite"></div>
        <div class="spacer-small"></div>
        <router-outlet></router-outlet>
    </mat-sidenav-content>
</mat-sidenav-container>
import { Component, Input, ViewChild } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { ToastContainerDirective, ToastrService } from 'ngx-toastr';
import { filter } from 'rxjs';
import { ConfigurationState, NavItem } from 'reg-hub-common';

@Component({
  selector: 'app-side-menu',
  templateUrl: './side-menu.component.html',
  styleUrls: ['./side-menu.component.css']
})
export class SideMenuComponent {
  @Input() isSidenavOpen: boolean = true;

  @ViewChild(ToastContainerDirective, { static: true }) toastContainer!: ToastContainerDirective

  selectedItem = '';
  isSearching = false;
  disableRipple = true;

  // This will come from a DB call to which nav items a user is allowed to see
  navItems: NavItem[] = [
    { label: 'Dashboard', icon: 'home', link: '/dashboard' },
    { label: 'Order History', icon: 'list_alt', link: '/orders' },
    { label: 'Reports', icon: 'summarize', link: '/reports' },
    { label: 'Accounting', icon: 'payment', link: '/accounting' }
  ];

  requestItems: NavItem[] = [
    { label: 'PPSA Registration', icon: 'draw', link: '/new-registration' },
    { label: 'PPSA Search', icon: 'search', link: '/new-search' }
  ];

  helpItems: NavItem[] = [
    { label: 'Information', icon: 'quiz', link: '/information-documents' },
    { label: 'Contact Us', icon: 'support_agent', link: '/contact-us' },
  ];

  constructor(
    private messageService: ToastrService,
    private configurationState: ConfigurationState,
    private router: Router) { }

  ngOnInit(): void {
    this.messageService.overlayContainer = this.toastContainer;
      
    this.setNavItems();

    this.router.events
      .pipe(filter(event => event instanceof NavigationStart))
      .subscribe(() => this.messageService.clear());
  }

  private setNavItems() {
    //TEMPORARY FIX
    // this.configurationState.hasUserManagementAccess().then(hasAccess => {
    //   if (hasAccess) {
        this.navItems = this.navItems.concat([{ label: 'Management', icon: 'settings', link: '/management' }]);
    //   }
    // });
  }

  handleClick(selectedItem: NavItem) {
    this.selectedItem = selectedItem.label;
  }

  onSearch(searchText: string) {
    this.isSearching = true;

    this.router.navigate(['orders', searchText]);
  }
}

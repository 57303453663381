import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatRadioChange } from '@angular/material/radio';
import { LienDetailsUIConfiguration } from 'reg-hub-common';
import { Order, OrderManagerService, Utilities, ValidationProblem } from 'reg-hub-common';
import { BehaviorSubject, Observable, Subject, takeUntil, tap } from 'rxjs';

@Component({
  selector: 'app-term',
  templateUrl: './term.component.html',
  styleUrls: ['./term.component.css']
})
export class TermComponent implements OnInit, OnDestroy {
  @Input() isDisabled: boolean = false;
  @Input() order!: Order;
  @Input() errors$!: Observable<ValidationProblem[] | undefined>;
  @Input() uiConfiguration: LienDetailsUIConfiguration | undefined;
  @Output() termFormValueChangedEvent = new EventEmitter<any>();

  termDetails!: FormGroup;

  private onDestroy$ = new Subject<void>();

  protected termError$ = new BehaviorSubject<string | null | undefined>(null);
  protected expiryDateError$ = new BehaviorSubject<string | null | undefined>(null);

  termLengths: { value: number, viewValue: string }[] = [];

  protected selectedTermType: number = -1;

  protected termInYearsSelection: number = 0;
  protected expiryDateSelection: number = 1;
  protected infiniteSelection: number = 2;

  constructor(private formBuilder: FormBuilder) { }

  ngOnInit(): void {
    if(!this.uiConfiguration) {
      return;
    }

    this.selectedTermType = this.getInitialTermSelection();

    this.termDetails = this.formBuilder.group({
      selectedTermType: [this.selectedTermType]
    }) as FormGroup;

    if(this.uiConfiguration.showTerm) {
      this.termDetails.addControl('term', this.formBuilder.control(this.order.lien?.term));
    }

    if (this.uiConfiguration.showExpiryDate) {
      this.termDetails.addControl(
        'expiryDate',
        this.formBuilder.control(this.order.lien?.expiryDate, [
          Validators.required,
          Utilities.validDateValidator(),
        ])
      );

      // Subscribe to the status changes of the expiryDate control
      this.termDetails.statusChanges.pipe(takeUntil(this.onDestroy$))
        .subscribe((status) => {
          if (status === 'INVALID') {
            const errors = this.termDetails.get('expiryDate')?.errors;
            if (errors?.['required']) {
              this.expiryDateError$.next('Expiry Date is required');
            } else if (errors?.['invalidDate']) {
              this.expiryDateError$.next('Invalid expiry date');
            } else {
              this.expiryDateError$.next('Invalid expiry date');
            }
          } else {
            this.expiryDateError$.next(null);
          }
        });
    }

    if(this.uiConfiguration.showInfinityTerm) {
      this.termDetails.addControl('isInfiniteTerm', this.formBuilder.control(this.order.lien?.isInfiniteTerm));
    }

    if(this.uiConfiguration.minTerm && this.uiConfiguration.maxTerm) {
      for(let i = this.uiConfiguration.minTerm; i <= this.uiConfiguration.maxTerm; i++) {
        this.termLengths.push({ value: i, viewValue: `${i} Year${i > 1 ? 's' : ''}` });
      }
    }

    this.termDetails.valueChanges
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(() => this.termFormValueChangedEvent.emit(this.termDetails.value));
    this.errors$
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(errors => this.pushErrors(errors));

    if(this.isDisabled) {
      this.termDetails.disable();
    }
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  getInitialTermSelection(): number {
    if(!this.uiConfiguration) {
      return 0;
    }

    if(this.order.lien?.expiryDate) {
      return this.expiryDateSelection;
    } else if(this.order.lien?.term) {
      return this.termInYearsSelection;
    } else if(this.order.lien?.isInfiniteTerm){
      return this.infiniteSelection;
    } else {
      if(this.uiConfiguration.showTerm) {
        return this.termInYearsSelection;
      } else if(this.uiConfiguration.showExpiryDate) {
        return this.expiryDateSelection;
      } else if(this.uiConfiguration.showInfinityTerm) {
        return this.infiniteSelection;
      } else {
        return -1;
      }
    }
  }

  onTermSelectionChange(event: MatRadioChange): void {
    this.selectedTermType = event.value;

    if (event.value === this.termInYearsSelection) {
      this.order.lien!.expiryDate = null;
      this.order.lien!.isInfiniteTerm = false;

      this.termDetails?.get('expiryDate')?.setValue(null, { emitEvent: false });
      this.termDetails?.get('isInfiniteTerm')?.setValue(false);
    } else if (event.value === this.expiryDateSelection) {
      this.order.lien!.term = null;
      this.order.lien!.isInfiniteTerm = false;

      this.termDetails?.get('term')?.setValue(null, { emitEvent: false });
      this.termDetails?.get('isInfiniteTerm')?.setValue(false);
    } else {
      this.order.lien!.term = null;
      this.order.lien!.expiryDate = null;
      this.order.lien!.isInfiniteTerm = true;

      this.termDetails?.get('term')?.setValue(null, { emitEvent: false });
      this.termDetails?.get('expiryDate')?.setValue(null, { emitEvent: false });
      this.termDetails?.get('isInfiniteTerm')?.setValue(true);
    }
  }

  public pushErrors(errors: ValidationProblem[] | undefined): void {
    if(!errors) {
      return;
    }

    const termError = errors.find(error => error.path.includes('/term'))?.userFriendlyMessage;
    this.termError$.next(termError);

    const expiryDateError = errors.find(error => error.path.includes('/expirydate'))?.userFriendlyMessage;
    this.expiryDateError$.next(expiryDateError);
  }
}

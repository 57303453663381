import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { ListComponent, Order } from 'reg-hub-common';
import { ExpiringReportRepositoryService } from 'reg-hub-common';

@Component({
    selector: 'app-expiring-report',
    templateUrl: './expiring-report.component.html',
    styleUrls: ['./expiring-report.component.css'],
    animations: [
      trigger('detailExpand', [
        state('collapsed,void', style({height: '0px', minHeight: '0'})),
        state('expanded', style({height: '*'})),
        transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
      ]),
    ],
})
export class ExpiringReportComponent extends ListComponent<Order> {
    protected _expandedOrder: Order | null = null;

  displayedColumns: string[] = [
    'referenceNumber', 
    'country',
    'jurisdiction',
    'expiryDate'
  ];

  columnsToDisplayWithView = [...this.displayedColumns, 'view', 'indicator'];

  constructor(
    repo: ExpiringReportRepositoryService,
    private router: Router) {
      super(repo);
   }

  protected viewOrder(order: Order, newTab: boolean) {
    if (newTab) {
      const url = this.router.serializeUrl(
        this.router.createUrlTree(['orders', order.id])
      );
  
      window.open(url, '_blank');
    }
    else {
      this.router.navigate(['orders', order.id])
    }
  }
}
import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject, take } from 'rxjs';
import { FiltersComponent, Order, ConfigurationState, ExpiringReportRepositoryService } from 'reg-hub-common';

@Component({
  selector: 'app-expiring-report-filter',
  templateUrl: './expiring-report-filter.component.html',
  styleUrls: ['./expiring-report-filter.component.css'],
  providers: [DatePipe]
})
export class ExpiringReportFilterComponent extends FiltersComponent<Order> implements OnInit {
  protected autoExpand: boolean = false;
  protected autoRedirect: boolean = false;
  protected _rangeOptions: number[] = [30, 60, 90, 180, 365];

  private unsubscribe$ = new Subject<void>();

  constructor(
    private configurationState: ConfigurationState,
    private route: ActivatedRoute,
    private reportRepo: ExpiringReportRepositoryService,
    formBuilder: FormBuilder,
    datePipe: DatePipe) {
    super(
        reportRepo,
      formBuilder,
      datePipe);
  }

  ngOnInit() {
    this._filterForm = this.formBuilder.group({
      range: [90],
      country: ['CA'],
      jurisdiction: [null],
      amountFrom: [null],
      amountTo: [null],
      termFrom: [null],
      termTo: [null],
      sortColumn: ['orderStatusUpdatedDate'],
      sortDirection: ['desc']
    })

    this.configurationState.getCorporationID().then(corpID => {
      this.reportRepo.populateBaseLink([corpID]);

    this._defaultParams = this.buildParamsFromForm(this._filterForm);

    this.route.queryParams.pipe(take(1)).subscribe(routeQueryStringParams => {
      const routeQueryStringParamKeys = Object.keys(routeQueryStringParams);

      if (routeQueryStringParamKeys.length > 0) {
        this.autoRedirect = routeQueryStringParams["autoRedirect"] == 'true';
        let preFilteredParams = this.buildParamsFromObject(routeQueryStringParams);

        routeQueryStringParamKeys.forEach(key => {
          const control = this._filterForm.get(key);

          if (control) {
            control.setValue(routeQueryStringParams[key]);
            this._filterForm.markAsDirty();
            this.autoExpand = routeQueryStringParams["autoExpand"] ?? false;
            this.filtered = true;
          }
        })

        this.repo.getFirstPage(preFilteredParams);
      } else {
        this.repo.getFirstPage(this._defaultParams);
      }
    });
  });
  }

  ngOnDestroy(): void {
    // Signal all subscriptions to complete
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  onReportRangeChanged() {
    this.repo.refresh();
  }

  protected jurisdictionFormChanges(event: FormGroup) {
    this._filterForm.setControl('country', event);
    this._filterForm.setControl('jurisdiction', event);
  }
}

<div class="padding">
    <form [formGroup]="lienDetails">
        <div class="flex-container">
            <div class="validated-form-field-container flex-container">
                <mat-form-field class="flex-item form-field" appearance="outline">
                    <mat-label>Registration Number</mat-label>
                    <input matInput name="registration-number" placeholder="Registration Number"
                        [formControlName]="'registrationNumber'">
                </mat-form-field>
                <lib-error-bubble [control]="lienDetails.controls['registrationNumber']"
                    [message]="registrationNumberError$"></lib-error-bubble>
            </div>
        </div>

        <div  class="flex-container">
            <div class="validated-form-field-container flex-container">
                <mat-form-field class="form-field flex-item" appearance="outline">
                    <mat-label>Registration Date</mat-label>
                    <input matInput [matDatepicker]="registrationDate" name="registration-date" formControlName="registrationDate"
                        placeholder="YYYY-MM-DD">
                    <mat-datepicker-toggle matSuffix [for]="registrationDate"></mat-datepicker-toggle>
                    <mat-datepicker #registrationDate></mat-datepicker>
                </mat-form-field>
                <lib-error-bubble [control]="lienDetails.get('registrationDate')!"
                    [message]="registrationDateError$"></lib-error-bubble>
            </div>
        </div>

        <app-term [uiConfiguration]="uiConfiguration.lienDetailsConfiguration!" [errors$]="observableErrors$"
            [order]="order" [isDisabled]="isDisabled"></app-term>
        
        <div *ngIf="uiConfiguration.lienDetailsConfiguration?.showAmount" class="flex-container">
            <div class="validated-form-field-container flex-container">
                <mat-form-field class="flex-item form-field" appearance="outline">
                    <mat-label>Amount</mat-label>
                    <input matInput name="amount" placeholder="Amount" [formControlName]="'amount'" type="number">
                </mat-form-field>
                <lib-error-bubble [control]="lienDetails.controls['amount']"
                    [message]="amountError$"></lib-error-bubble>
            </div>
        </div>

        <div *ngIf="uiConfiguration.lienDetailsConfiguration?.showAdditionalInformation" class="flex-container">
            <div class="validated-form-field-container flex-container">
                <mat-form-field class="flex-item form-field" appearance="outline">
                    <mat-label>Additional Information</mat-label>
                    <textarea matInput name="additional-information" [formControlName]="'additionalInformation'"
                        placeholder="Additional information"></textarea>
                </mat-form-field>
                <lib-error-bubble [control]="lienDetails.controls['additionalInformation']"
                    [message]="additionalInformationError$"></lib-error-bubble>
            </div>
        </div>
    </form>
</div>
<form [formGroup]="_filterForm" class="padding">
    <div class="flex-container">
        <mat-form-field class="form-field">
            <mat-select [formControlName]="'range'" placeholder="Range" (selectionChange)="onFilter()">
                <mat-option *ngFor="let option of _rangeOptions" [value]="option">
                    Expiring in {{ option }} Days
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>
    <mat-expansion-panel [expanded]="autoExpand">
        <mat-expansion-panel-header>
            <mat-panel-title class="bold">
                Filters
            </mat-panel-title>
        </mat-expansion-panel-header>

        <lib-jurisdiction-selection (formReady)="jurisdictionFormChanges($event)"
            [defaultCountry]="'CA'"></lib-jurisdiction-selection>

        <div class="flex-container">
            <mat-form-field class="form-field flex-item">
                <mat-label>Amount From</mat-label>
                <input matInput [formControlName]="'amountFrom'" placeholder="Amount From" type="number" />
            </mat-form-field>
            <mat-form-field class="form-field flex-item">
                <mat-label>Amount To</mat-label>
                <input matInput [formControlName]="'amountTo'" placeholder="Amount To" type="number" />
            </mat-form-field>
        </div>

        <div class="flex-container">
            <mat-form-field class="form-field flex-item">
                <mat-label>Term From</mat-label>
                <input matInput [formControlName]="'termFrom'" placeholder="Term From" type="number" />
            </mat-form-field>
            <mat-form-field class="form-field flex-item">
                <mat-label>Term To</mat-label>
                <input matInput [formControlName]="'termTo'" placeholder="Term To" type="number" />
            </mat-form-field>
        </div>

        <div class="spacer-small"></div>
        <div>
            <button mat-raised-button color="accent" class="padding-sides-5" aria-label="Clear filters button"
                (click)="onClear()" [disabled]="!_filterForm.dirty">
                Clear Filters
            </button>
            <button mat-raised-button color="accent" class="padding-sides-5" aria-label="Filter button" type="submit"
                (click)="onFilter()">
                Filter
            </button>
        </div>

    </mat-expansion-panel>
</form>
<div class="padding">
    <h1 class="left-box">Active Registrations</h1>

    <mat-card>
        <mat-card-content>
            <!-- Container div for everything -->
            <div *ngIf="this.repo.resource$ | async as data">

                <app-actives-report-filter></app-actives-report-filter>

                <div class="spacer-small"></div>

                <!-- Main orders table -->
                <div class="table-container" *ngIf="data">

                    <!-- Conditional paginator at the top (show only when number of orders displayed is >= 50) -->
                    <div *ngIf="data.length >= 50">
                        <mat-paginator [pageSize]="this.repo.pageSize$ | async"
                            [length]="this.repo.resourceCount$ | async" [pageIndex]="this.repo.pageNum$ | async"
                            [pageSizeOptions]="[10, 25, 50, 100]" showFirstLastButtons="true"
                            (page)="page($event)"></mat-paginator>
                        <div class="spacer-small"></div>
                    </div>
                    <!-- End of conditional paginator at the top -->

                    <!-- Orders table -->
                    <table mat-table matSort [dataSource]="data" multiTemplateDataRows class="mat-elevation-z8"
                        (matSortChange)="sort($event)" *ngIf="data.length > 0">
                        <ng-container matColumnDef="referenceNumber">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header id="referenceNumber"> Ref No.
                            </th>
                            <td mat-cell *matCellDef="let order"> {{order.referenceNumber}} </td>
                        </ng-container>

                        <ng-container matColumnDef="country">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header id="country"> Country </th>
                            <td mat-cell *matCellDef="let order"> {{order.country}} </td>
                        </ng-container>

                        <ng-container matColumnDef="jurisdiction">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header id="jurisdiction"> Jurisdiction
                            </th>
                            <td mat-cell *matCellDef="let order"> {{order.jurisdiction}} </td>
                        </ng-container>

                        <ng-container matColumnDef="registrationDate">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header id="registrationDate"> Registration Date
                            </th>
                            <td mat-cell *matCellDef="let order"> {{order.lien?.registrationDate | localizedDate: 'yyyy-MM-dd' }} </td>
                        </ng-container>

                        <ng-container matColumnDef="expiryDate">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header id="expiryDate"> Expiry Date
                            </th>
                            <td mat-cell *matCellDef="let order"> {{order.lien?.expiryDate | localizedDate: 'yyyy-MM-dd' }} </td>
                        </ng-container>

                        <ng-container matColumnDef="view">
                            <th mat-header-cell *matHeaderCellDef aria-label="row actions">&nbsp;</th>
                            <td mat-cell *matCellDef="let order">
                                <button mat-icon-button color="accent" (click)="viewOrder(order, false)" aria-label="View Order">
                                    <mat-icon>visibility</mat-icon>
                                </button>
                                <button mat-icon-button color="accent" (click)="viewOrder(order, true)" aria-label="View Order">
                                    <mat-icon>chevron_right</mat-icon>
                                </button>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="indicator">
                            <th mat-header-cell *matHeaderCellDef id="indicator"></th>
                            <td mat-cell *matCellDef="let order">
                                <lib-disclosure-indicator [isExpanded]="order == _expandedOrder"></lib-disclosure-indicator>
                            </td>
                        </ng-container>

                        <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
                        <ng-container matColumnDef="expandedDetail">
                            <td mat-cell *matCellDef="let order" [attr.colspan]="columnsToDisplayWithView.length">
                                <div class="expanded-entity-detail"
                                    [@detailExpand]="order == _expandedOrder ? 'expanded' : 'collapsed'">
                                    <div class="expanded-entity-information">
                                        <lib-order-preview-host [order]="order"></lib-order-preview-host>
                                    </div>
                                </div>
                            </td>
                        </ng-container>

                        <tr mat-header-row *matHeaderRowDef="columnsToDisplayWithView"></tr>
                        <tr mat-row *matRowDef="let order; columns: columnsToDisplayWithView;"
                            class="expandable-table-row" [class.example-expanded-row]="_expandedOrder === order"
                            (click)="_expandedOrder = _expandedOrder === order ? null : order">
                        </tr>
                        <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="expandable-detail-row">
                        </tr>

                    </table>
                    <!-- End of orders table-->

                    <!-- Message while loading (show only if data.length is 0 and loading) -->
                    <div class="padding center padding-top-bottom-50"
                        *ngIf="((this.repo.loading$ | async) ?? false) && data.length === 0">
                        &nbsp;
                    </div>

                    <!-- Paginator for the bottom -->
                    <div class="spacer-small"></div>

                    <mat-paginator *ngIf="data.length > 0" [pageSize]="this.repo.pageSize$ | async"
                        [length]="this.repo.resourceCount$ | async" [pageIndex]="this.repo.pageNum$ | async"
                        [pageSizeOptions]="[10, 25, 50, 100]" showFirstLastButtons="true"
                        (page)="page($event)"></mat-paginator>
                    <!-- End of paginator for the bottom -->
                </div>
                <!-- End of main orders table-->

                <!-- Spinner (shows over entire card) -->
                <div *ngIf="this.repo.loading$ | async" class="loading-spinner-overlay">
                    <mat-spinner></mat-spinner>
                </div>
                <!-- End of spinner -->

                <lib-list-issue [repo]="this.repo" [data]="data"></lib-list-issue>

            </div>
            <!-- End of container div for everything -->
        </mat-card-content>
    </mat-card>
</div>